import { createBrowserRouter } from 'react-router-dom';
import Home from "./home";
import Error from "./error";
import AccountAggregatorConsent from './features/accountAggregator/pages/Consent';
import AccountAggregatorConsentCallback from "./features/accountAggregator/pages/ConsentCallback";
 
const routes = createBrowserRouter([
{
    path: '/',
    errorElement: <Error />,
    children: [
        {
            index: true,
            element: <Home />    
        },
        {
            path: '/browser/account-aggregator/consent/:RequestId',
            element: <AccountAggregatorConsent />
        },
        {
            path: '/browser/account-aggregator/callback',
            element: <AccountAggregatorConsentCallback />
        }
    ] 
}
]);
  
export default routes;