import axios from "axios";
import { ConsentRequest } from "./models/ConsentRequest";
import { ConsentValidationResult } from "./models/ConsentValidationResult";
import { SaveConsentResponse } from "./models/SaveConsentResponse";
import baseUrls from "../../../config/service";

export function validateConsentRequest(requestId: string) {
    return new Promise<ConsentValidationResult<ConsentRequest>>((resolve, reject) => {

        const baseURL = baseUrls.accountAggregator + "/consent/"+ requestId;
        // based on the request Id fetch the consent Link
        // if we failed to load throw an exception....
        axios
        .get(baseURL)
        .then((response) => {

          const validationResult = {
            ValidationResult : false,
            Request: undefined
          }

          if (response.data.Code === 0 && response.data.Errors.length === 0) {
            validationResult.ValidationResult = true;
            validationResult.Request = response.data.PayLoad;
            resolve(validationResult);
            return;
          }

          if (response.data.Code !== 0 && response.data.Errors.length > 0 ) {

              validationResult.ValidationResult = false;
              validationResult.Request = undefined;
              resolve(validationResult);
              return;
          }

          reject("We received unknown error with http status code : " + response.statusText);
        })
        .catch(e => reject("We received unknown error"));
  });
}  

export function saveConsentRequest(requestId: string) {
  return new Promise<SaveConsentResponse>((resolve, reject) => {

  const baseURL = baseUrls.accountAggregator + "/request/capture/consent";
  // based on the request Id fetch the consent Link
  // if we failed to load throw an exception....

    axios.post(baseURL, {RequestId : requestId}).then((response) => {
      if (response.data.Code === 0 && response.data.Errors.length === 0) {
        resolve({ 
          RequestId : requestId, 
          ConsentLink: response.data.PayLoad.ConsentLink, 
          ConsentStatus: response.data.PayLoad.ConsentCaptured ,
          ConsentRequestStatus: response.data.PayLoad.ConsentRequestStatus
        });
      } else {
        reject(response.data.Errors[0].Message);
      }
    }).catch(e => {
      reject(e.message || "Failed to capture consent");
    });
});
}

export function callbackConsentRequest( req : {requestId: string, search: any}) {
  return new Promise<{ result: { requestId: string, redirectLink: string, redirectStatus: boolean }, error?: string | null   }>((resolve, reject) => {

  const baseURL = baseUrls.accountAggregator + "/request/provider-callback/"+req.requestId + "?" + req.search.toString();

  // based on the request Id fetch the consent Link
  // if we failed to load throw an exception....
  axios.get(baseURL).then((response) => {

    if (response.data.Code === 0 && response.data.Errors.length === 0) {
      resolve({ 
        result : {
          requestId : req.requestId, 
          redirectLink: response.data.PayLoad.url, 
          redirectStatus: response.data.PayLoad.status
        },
        error: null
      });
    } 

    if (response.data.Code === 1 && response.data.Errors.length > 0) {
      resolve({ 
        result: {
          requestId : req.requestId, 
          redirectLink: response.data.PayLoad.url, 
          redirectStatus: response.data.PayLoad.status
        },
        error: response.data.Errors[0].Message || "Failed to get data"
      });
    } else {
      reject(response.data.Errors[0].Message);
    }
  }).catch(e => reject("Failed to get data"));
});
} 
