import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState} from '../../../app/store';
import { saveConsentRequest, 
  validateConsentRequest, 
  callbackConsentRequest,
} from '../apis/ConsentApi';
import { ConsentValidationResult } from '../apis/models/ConsentValidationResult';
import { ConsentRequest } from '../apis/models/ConsentRequest';
import { SaveConsentResponse } from '../apis/models/SaveConsentResponse';

export interface ConsentState {
  consent: boolean;
  ConsentValidationResult: ConsentValidationResult<ConsentRequest>;
  SaveConsentResponse: SaveConsentResponse;
  callbackResult: any;
  loading: 'idle' | 'loading' | 'failed';
  error : any;
}

const initialState: ConsentState = {
  consent: false,
  ConsentValidationResult: undefined as any,
  SaveConsentResponse: undefined as any,
  callbackResult: null,
  loading: 'idle',
  error : null,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const validateConsentRequestAsync = createAsyncThunk(
  'consent/validateRequest',
  async (requestId: string) => {
    const response = await validateConsentRequest(requestId);

    return response;
  }
);

export const saveConsentRequestAsync = createAsyncThunk(
  'consent/saveConsentRequest',
  async (requestId: string) => {
    const response = await saveConsentRequest(requestId);

    return response;
  }
);

//callbackConsentRequest
export const callbackConsentRequestAsync = createAsyncThunk(
  'consent/callbackConsentRequest',
  async (req : {requestId: string, search: any}) => {
    const response = await callbackConsentRequest({requestId: req.requestId, search: req.search});

    return response;
  }
);


export const consentSlice = createSlice({
  name: 'consent',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setConsent:(state, action: PayloadAction<boolean>) => {
      state.consent = action.payload;
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(validateConsentRequestAsync.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(validateConsentRequestAsync.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.ConsentValidationResult = action.payload;
      })
      .addCase(validateConsentRequestAsync.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })
      .addCase(saveConsentRequestAsync.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(saveConsentRequestAsync.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.SaveConsentResponse = action.payload;
      })
      .addCase(saveConsentRequestAsync.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })
      .addCase(callbackConsentRequestAsync.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(callbackConsentRequestAsync.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.error = action.payload.error;
        state.callbackResult = action.payload.result; 
      })
      .addCase(callbackConsentRequestAsync.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })
  }
});

export const { setConsent } = consentSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectConsent = (state: RootState) => state.consent;

export default consentSlice.reducer;