import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import { Alert, Card, CardContent, Grid } from "@mui/material";
import Button from "@mui/material/Button";

import { ConsentRequestStatus } from "../apis/models/ConsentRequestStatus";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
    validateConsentRequestAsync,
    setConsent,
    saveConsentRequestAsync,
    ConsentState,
} from "../redux/ConsentSlice";

import logo from "../images/edgrologo.png";
import "../styles/Consent.scss";

function Consent() {
    const [accept, setAccept] = useState(false);
    const checkBox = useRef(null);
    const proceedButton = useRef(null);
    const dispatch = useAppDispatch();
    const {
        consent,
        ConsentValidationResult,
        SaveConsentResponse,
        loading,
        error,
    } = useAppSelector(
        (state: any): ConsentState =>
            state.consent as ConsentState
    );

    const params = useParams();
    const requestId = params.RequestId;


    useEffect(() => {
        if (!ConsentValidationResult) {
            dispatch(
                validateConsentRequestAsync(
                    requestId as string
                ) as any
            );
        }
    }, [requestId, dispatch, params, ConsentValidationResult]);

    useEffect(() => {
        if (
            consent &&
            accept &&
            SaveConsentResponse &&
            SaveConsentResponse.ConsentStatus &&
            SaveConsentResponse.ConsentLink
        ) {
            window.location.replace(
                SaveConsentResponse.ConsentLink
            );
        }
    }, [accept, SaveConsentResponse, consent]);

    useEffect(() => {
        if (
            SaveConsentResponse &&
            SaveConsentResponse.ConsentStatus
        ) {
            setAccept(consent);
        }
    }, [SaveConsentResponse, consent]);

    const consentCheck = () => {
        const isChecked = (checkBox.current as any).checked;
        dispatch(setConsent(isChecked));
    };

    const proceed = () => {
        if (consent) {
            dispatch(
                saveConsentRequestAsync(
                    requestId as string
                ) as any
            );
        }
    };

    const getValidationResult = (): Boolean | undefined => {
        if (ConsentValidationResult) {
            return ConsentValidationResult.ValidationResult;
        }
        return false;
    };

    const renderConsent = () => {
        if (loading === "loading") {
            return <CircularProgress />;
        }

        if (
            !getValidationResult() ||
            error ||
            loading !== "idle" ||
            !ConsentValidationResult.Request
        ) {
            return (
                <Grid item xs={6} md={4} className="error-badge">
                    <Alert severity="error">
                        We could not able to verify your consent request. Please
                        contact support team.
                    </Alert>
                </Grid>
            );
        }

        if (
            [ConsentRequestStatus.PENDING].includes(
                ConsentValidationResult.Request.Status
            )
        ) {
            return (
                <Grid item xs={6} md={4} className="consent-content">
                    <Card raised={true} className="consent-card">
                        <CardContent className="card-content">
                            <Grid>
                                <div className="user-consent-selection">
                                    <span>
                                        <input
                                            type="checkbox"
                                            onChange={consentCheck}
                                            ref={checkBox}
                                            checked={consent}
                                            className="input-checkbox"
                                        ></input>
                                    </span>
                                    <span className="consent-desc">
                                        I provide consent to Edgro Finance Private Limited to access/download my Bank Statement documents and share them with Bluebear Technology Private Limited and its lending partners.
                                    </span>
                                </div>
                            </Grid>
                            <Grid className="action-container">
                                <Button
                                    variant="contained"
                                    ref={proceedButton}
                                    disabled={
                                        consent === false ||
                                        (SaveConsentResponse &&
                                            SaveConsentResponse.ConsentStatus)
                                    }
                                    onClick={proceed}
                                    className="proceed-button"
                                >
                                    {" "}
                                    Proceed{" "}
                                </Button>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            );
        }

        if (
            [ConsentRequestStatus.ACTIVE].includes(
                ConsentValidationResult.Request.Status
            )
        ) {
            return (
                <Grid item xs={6} md={4} className="error-badge">
                    <Alert severity="error">
                        The request has already been processed.
                    </Alert>
                </Grid>
            );
        }

        return (
            <Grid item xs={6} md={4} className="error-badge">
                <Alert severity="error">
                    There is problem with this request. Please contact support
                    team.
                </Alert>
            </Grid>
        );
    };

    return (
        <React.Fragment>
            <Grid className="header">
                <img alt="logo" src={logo} className="edgro-logo"></img>
            </Grid>
            <Grid
                container
                spacing={2}
                direction="column"
                alignItems="center"
                justifyContent="center"
                className="consent-container"
            >
                {renderConsent()}
            </Grid>
        </React.Fragment>
    );
}

export default Consent;
