import { Grid } from '@mui/material';

function Home() {
  return (
    <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justifyContent="center"
    sx={{ minHeight: '100vh' }}>
    <Grid item xs={3}>
      <h1>Edgro Account Aggregator Home Page...</h1>
      </Grid>
    </Grid>
  );
}

export default Home;